import { connect } from "react-redux";
import Button from "react-bootstrap/Button";

function resetRange() {
    return { type: 'RESET_RANGE' }
}
function selectMenu(menu) {
    return { type: 'SELECT_MENU', menu: menu }
}
function setLoading() {
    return { type: 'SET_LOADING', loading: true }
}

const Menu = (props) => {
    let randomLegacy = () => {
        props.resetRange();
        props.setLoading();
        props.selectMenu(2);
    };
    let randomStack = () => {
        props.resetRange();
        props.setLoading();
        props.selectMenu(3);
    }

    return (
        <div>
            <h2 className="fw-bold text-muted">Menu</h2>
            <hr></hr>
            <div className="d-grid">
                <Button
                    variant="light" size="sm" title="common randomize without removing popped num"
                    className="rounded-pill px-3 mb-2 d-flex align-items-center"
                    type="button" onClick={randomLegacy}>
                    <span className="material-symbols-outlined me-3 rn-menu1-icon p-1 rounded-5 text-white">shuffle</span>
                    <div>
                        <div className="text-start">Random number</div>
                        <div className="text-secondary text-start">common randomize</div>
                    </div>
                </Button>
                <Button
                    variant="light" size="sm" title="popped number will be removed and listed"
                    className="rounded-pill px-3 mb-2 d-flex align-items-center"
                    type="button" onClick={randomStack}>
                    <span className="material-symbols-outlined me-3 rn-menu2-icon p-1 rounded-5 text-white">filter_1</span>
                    <div>
                        <div className="text-start">Random number stack</div>
                        <div className="text-secondary text-start">popped num removed</div>
                    </div>
                </Button>
                <div className="rounded-pill p-2 px-3 mb-2 d-flex align-items-center bg-light">
                    <span className="material-symbols-outlined me-3 rn-menunx-icon p-1 rounded-5 text-secondary">more_horiz</span>
                    <div className="text-secondary text-start">more comming soon</div>
                </div>
            </div>
        </div>
    );
};

function mapStateToProps(state) {
    return {
        menu: state.menu
    }
}
const dispatchToProps = { resetRange, selectMenu, setLoading }

export default connect(mapStateToProps, dispatchToProps)(Menu);