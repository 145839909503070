import { connect } from "react-redux";
import { useState } from 'react';
import { Alert, Container, Navbar, Button, Collapse } from 'react-bootstrap';

function resetRange() {
    return { type: 'RESET_RANGE' }
}
function selectMenu(menu) {
    return { type: 'SELECT_MENU', menu: menu }
}
function setLoading() {
    return { type: 'SET_LOADING', loading: true }
}

const Header = (props) => {
    const [menuOpen, setMenuOpen] = useState(false);

    let randomLegacy = () => {
        setMenuOpen(!menuOpen);
        props.resetRange();
        props.setLoading();
        props.selectMenu(2);
    };
    let randomStack = () => {
        setMenuOpen(!menuOpen);
        props.resetRange();
        props.setLoading();
        props.selectMenu(3);
    }

    return (
        <div id="rn-header" className="header">
            <Navbar className="mb-4">
                <Container>
                    <Navbar.Brand href="#">
                        <div className='d-flex align-items-center'>
                            <img src='rnlogo192.png' className='rn-title-h me-3' alt="brand logo" />
                            <h2 className='mb-0 rn-title-fs'>Shufflr</h2>
                        </div>
                    </Navbar.Brand>
                    <Navbar.Collapse className="justify-content-end">
                        <Navbar.Text>
                            <Button
                                variant="light"
                                className="position-absolute d-flex p-2 rounded-5 bottom-0 end-0 me-3 mb-3 d-md-none shadow"
                                title="popped number"
                                onClick={() => setMenuOpen(!menuOpen)}
                                aria-controls="collapse-menu"
                                aria-expanded={menuOpen}>
                                <span className="material-symbols-outlined">menu</span>
                            </Button>
                        </Navbar.Text>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Collapse in={menuOpen}>
                <div id="collapse-menu">
                    <h2 className="fw-bold text-muted">Menu</h2>
                    <hr></hr>
                    <div className="d-grid">
                        <Button
                            variant="light" size="sm" title="common randomize without removing popped num"
                            className="rounded-pill px-3 mb-2 d-flex align-items-center"
                            type="button" onClick={randomLegacy}>
                            <span className="material-symbols-outlined me-3 rn-menu1-icon p-1 rounded-5 text-white">shuffle</span>
                            <div>
                                <div className="text-start">Random number</div>
                                <div className="text-secondary text-start">common randomize</div>
                            </div>
                        </Button>
                        <Button
                            variant="light" size="sm" title="popped number will be removed and listed"
                            className="rounded-pill px-3 mb-2 d-flex align-items-center"
                            type="button" onClick={randomStack}>
                            <span className="material-symbols-outlined me-3 rn-menu2-icon p-1 rounded-5 text-white">filter_1</span>
                            <div>
                                <div className="text-start">Random number stack</div>
                                <div className="text-secondary text-start">popped num removed</div>
                            </div>
                        </Button>
                        <div className="rounded-pill p-2 px-3 mb-2 d-flex align-items-center bg-light">
                            <span className="material-symbols-outlined me-3 rn-menunx-icon p-1 rounded-5 text-secondary">more_horiz</span>
                            <div className="text-secondary text-start">more comming soon</div>
                        </div>
                    </div>
                </div>
            </Collapse>
            <Alert variant="success" className="rounded-4 mb-4 mt-3 shadow">
                <Alert.Heading>Welcome,</Alert.Heading>
                <p>
                    This is simple webapp to randomize a range of number using ReactJs framework.
                    This project currently work in progress. So stay tunned!
                </p>
                <hr />
                <p className="mb-0">
                    Fork me at <a href='https://github.com/Alextrapixel/reactndom' target={'_blank'} rel="noreferrer">github</a>
                </p>
            </Alert>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        menu: state.menu
    }
}
const dispatchToProps = { resetRange, selectMenu, setLoading }

export default connect(mapStateToProps, dispatchToProps)(Header);